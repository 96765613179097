<template>
  <div class="sidebar vh-100" :class="{ open: sidebarOpen }">
    <div
      class="btn sidebar--open__btn mt-1 me-1 ms-1"
      @click="sidebarOpen = !sidebarOpen"
    >
      <font-awesome-icon :icon="['fas', 'xmark']" style="color: #ffffff" />
    </div>
    <div class="sidebar--container" :class="{ 'scroll-hidden': !scrollNow}">
      <div class="sidebar__title text-center">
        <router-link
          class="sidebar__link"
          active-class="sidebar__link--active"
          :to="{ name: SCHOOL_ROUTER_NAMES.home }"
        >
          <img :src="logoBelowText" class="sidebar-logo" alt="logo" />
        </router-link>
      </div>
      <router-link
        class="sidebar__link"
        active-class="sidebar__link--active"
        v-for="(item, index) in sidebars"
        :key="index"
        :to="item.link"
      >
        <div
          v-if="item.accountTypes.includes(currentUser?.accountTypes?.cd)"
          class="sidebar__item"
        >
          {{ item.title }}
          <font-awesome-icon
            :icon="['fas', 'chevron-right']"
            class="icon__chevron--right"
          />
        </div>
      </router-link>
      <router-link
        class="sidebar__link"
        active-class="sidebar__link--active"
        :to="{ name: SCHOOL_ROUTER_NAMES.account }"
        :class="{
          'sidebar__link--active': [
            SCHOOL_ROUTER_NAMES.account,
            SCHOOL_ROUTER_NAMES.emailChange,
            SCHOOL_ROUTER_NAMES.passwordChange,
          ].includes($route.name),
        }"
      >
        <div
          v-if="
            [ACCOUNT_TYPES.UNIVERSITY_ADMIN].includes(
              currentUser?.accountTypes?.cd
            )
          "
          class="sidebar__item"
        >
          {{ $t("store.menu_items.account_setting") }}
          <font-awesome-icon
            :icon="['fas', 'chevron-right']"
            class="icon__chevron--right"
          />
        </div>
      </router-link>
      <a href="https://link-cmn.co.jp/terms-university/" class="sidebar__link" target="_blank">
        <div class="sidebar__item">
          {{ $t("school.menu_items.terms_university") }}
          <font-awesome-icon :icon="['fas', 'chevron-right']" class="icon__chevron--right" />
        </div>
      </a>
      <a href="https://link-cmn.co.jp/terms-company/" class="sidebar__link" target="_blank">
        <div class="sidebar__item">
          {{ $t("school.menu_items.terms_company") }}
          <font-awesome-icon :icon="['fas', 'chevron-right']" class="icon__chevron--right" />
        </div>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/layouts/sidebar.scss";
</style>

<script>
import { LOGOUT } from "@/store/actions.type";
import routesNames from "@/router/routesNames";
import emitter from "@/mitt";
import { SCHOOL_ROUTER_NAMES } from "@/router/schoolGroup";
import { ACCOUNT_TYPES } from "@/constants";

export default {
  name: "Sidebar",
  setup() {
    return {
      SCHOOL_ROUTER_NAMES,
      ACCOUNT_TYPES,
    };
  },
  created() {
    emitter.on(`toggleSidebar`, () => {
      this.sidebarOpen = !this.sidebarOpen;
    });
  },
  computed: {
    sidebars() {
      return [
        {
          title: this.$t("school.menu_items.list"),
          link: { name: SCHOOL_ROUTER_NAMES.studentAccounts },
          accountTypes: [ACCOUNT_TYPES.UNIVERSITY_ADMIN],
        },
        {
          title: this.$t("school.menu_items.issue"),
          link: { name: SCHOOL_ROUTER_NAMES.students },
          accountTypes: [ACCOUNT_TYPES.UNIVERSITY_ADMIN],
        },
        {
          title: this.$t("school.menu_items.staff"),
          link: { name: SCHOOL_ROUTER_NAMES.staff },
          accountTypes: [ACCOUNT_TYPES.UNIVERSITY_ADMIN],
        },
        {
          title: this.$t("school.menu_items.grant_point_student"),
          link: { name: SCHOOL_ROUTER_NAMES.pointAwarded },
          accountTypes: [
            ACCOUNT_TYPES.UNIVERSITY_ADMIN,
            ACCOUNT_TYPES.UNIVERSITY_USER,
          ],
        },
        {
          title: this.$t("school.menu_items.point_auto_grant"),
          link: { name: SCHOOL_ROUTER_NAMES.automaticPointAwarded },
          accountTypes: [
            ACCOUNT_TYPES.UNIVERSITY_ADMIN,
            ACCOUNT_TYPES.UNIVERSITY_USER,
          ],
        },
        {
          title: this.$t("school.menu_items.point_grant_history"),
          link: { name: SCHOOL_ROUTER_NAMES.pointHistories },
          accountTypes: [
            ACCOUNT_TYPES.UNIVERSITY_ADMIN,
            ACCOUNT_TYPES.UNIVERSITY_USER,
          ],
        },
        {
          title: this.$t("school.menu_items.point_use_history"),
          link: { name: SCHOOL_ROUTER_NAMES.pointUsed },
          accountTypes: [ACCOUNT_TYPES.UNIVERSITY_ADMIN],
        },
        {
          title: this.$t("school.menu_items.department_setting"),
          link: { name: SCHOOL_ROUTER_NAMES.faculty },
          accountTypes: [ACCOUNT_TYPES.UNIVERSITY_ADMIN],
        },
        {
          title: this.$t("student.menu_items.info_change"),
          link: { name: SCHOOL_ROUTER_NAMES.info },
          accountTypes: [ACCOUNT_TYPES.UNIVERSITY_ADMIN],
        },
      ];
    },
    logoBelowText() {
      return this.$store.state.auth?.logo?.logoBelowText || require('@/assets/preme.png');
    },
  },
  beforeUnmount() {
    emitter.off("toggleSidebar");
  },
  data() {
    return {
      sidebarOpen: false,
      scrollNow: false,
      timer: false,
    };
  },
  mounted: function () {
    document
      .querySelector(".sidebar--container")
      .addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollNow = true;
      if (this.timer != false) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.scrollNow = false;
      }, 2000);
    },
    logout() {
      this.$store.dispatch(LOGOUT, routesNames.school[0]);
    },
  },
};
</script>
