import {
  SCHOOL_CHANGE_POINT_FOR_STUDENT,
  SCHOOL_CREATE_FACULTY,
  SCHOOL_CREATE_STUDENT,
  SCHOOL_GET_DETAIL,
  SCHOOL_UPDATE,
  SCHOOL_DELETE_FACULTY,
  SCHOOL_GET_FACULTIES,
  SCHOOL_UPDATE_STUDENT_STATUSES,
  SCHOOL_GET_STUDENT_DETAIL,
  SCHOOL_UPDATE_STUDENT,
  SCHOOL_GET_HISTORY_POINT_STATUSES,
  SCHOOL_GET_HISTORY_POINT_STUDENT,
  SCHOOL_GET_HISTORY_POINT_USED,
  SCHOOL_GET_DETAIL_HISTORY_POINT,
  SCHOOL_GET_LIST_AUTOMATIC_POINT,
  SCHOOL_CREATE_AUTOMATIC_POINT,
  SCHOOL_GET_DETAIL_AUTOMATIC_POINT,
  SCHOOL_UPDATE_AUTOMATIC_POINT,
  SCHOOL_DELETE_AUTOMATIC_POINT,
  SCHOOL_IMPORT_STUDENTS,
  SCHOOL_DOWNLOAD_CSV,
  SCHOOL_GET_LIST_STAFF,
  SCHOOL_CREATE_STAFF,
  SCHOOL_IMPORT_STAFF,
  SCHOOL_IMPORT_STUDENTS_VALIDATION,
} from "../actions.type";
import {
  SET_SCHOOL_DETAIL,
  SET_ERROR,
  SET_LIST_FACULTIES,
  SET_UPDATED_DATA,
  SET_STUDENT_DETAIL,
  SET_HISTORY_POINT_STATUSES,
  SET_HISTORY_POINT_STUDENT,
  SET_HISTORY_POINT_USED,
  SET_DETAIL_HISTORY_POINT,
  SET_LIST_STORE,
  SET_LIST_AUTOMATIC_POINT,
  SET_DETAIL_AUTOMATIC_POINT,
  SET_LOADING,
  SET_LIST_STAFF,
  SET_LIST_VALIDATE_ERRORS
} from "../mutations.type";
import {
  SchoolAddPointForStudent,
  SchoolCreateFaculty,
  SchoolCreateStudent,
  SchoolDeleteFaculty,
  SchoolGetDetail,
  SchoolGetFaculties,
  SchoolUpdate,
  SchoolUpdateStudentStatuses,
  SchoolGetStudentDetail,
  SchoolUpdateStudent,
  SchoolGetHistoryPointStudent,
  SchoolGetHistoryPointUsed,
  SchoolGetHistoryPointStatuses,
  SchoolGetDetailHistoryPoint,
  SchoolGetListAutomaticPoint,
  SchoolCreateAutomaticPoint,
  SchoolGetAutomaticPointDetail,
  SchoolUpdateAutomaticPoint,
  SchoolDeleteAutomaticPoint,
  SchoolImportStudents,
  SchoolDownloadCSV,
  SchoolGetListStaff,
  SchoolCreateStaff,
  SchoolImportStaff,
  SchoolImportStudentsValidation
} from "@/services/api/schools.service";
import { IAddPointForStudentRequest, ICreateStudentRequest, IRefundPointForStudentRequest, IUpdateSchoolValidRequest } from "@/services/api/models/schools/ISchool";
import router from "@/router";
import { SCHOOL_ROUTER_NAMES } from "@/router/schoolGroup";

const state = {
  errors: null,
  updatedData: null,
  loading: false,
  faculties: [],
  school: {},
  student: {},
  historyPointStudent: [],
  historyPointUsed: [],
  historyPointStatuses: [],
  detailHistoryPoint: [],
  listStores: {},
  automaticPoints: {},
  automaticPoint: {},
  listValidateErrors: {},
};

const getters = {};

const actions = {
  async [SCHOOL_CREATE_STUDENT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: ICreateStudentRequest
  ) {
    try {
      context.commit(SET_LOADING, true);
      const response = await SchoolCreateStudent(body);
      context.commit(SET_ERROR, null);
      context.commit(SET_LOADING, false);
      return response;
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_GET_LIST_STAFF](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await SchoolGetListStaff(query);
      context.commit(SET_LIST_STAFF, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_CREATE_STAFF](
    context: { commit: (arg0: string, arg1: any) => void },
    data: any
  ) {
    try {
      await SchoolCreateStaff(data);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_IMPORT_STAFF](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await SchoolImportStaff(body);
      context.commit(SET_LOADING, false);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_CHANGE_POINT_FOR_STUDENT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: IAddPointForStudentRequest
  ) {
    try {
      context.commit(SET_LOADING, true);
      await SchoolAddPointForStudent(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_GET_FACULTIES](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any,
  ) {
    try {
      const response = await SchoolGetFaculties(query);
      context.commit(SET_LIST_FACULTIES, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_CREATE_FACULTY](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await SchoolCreateFaculty(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_DELETE_FACULTY](
    context: { commit: (arg0: string, arg1: any) => void },
    facultyId: number,
  ) {
    try {
      await SchoolDeleteFaculty(facultyId);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [SCHOOL_GET_DETAIL](
    context: { commit: (arg0: string, arg1: any) => void },
    schoolId: number,
  ) {
    try {
      const response = await SchoolGetDetail(schoolId);
      context.commit(SET_SCHOOL_DETAIL, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [SCHOOL_UPDATE](
    context: { commit: (arg0: string, arg1: any) => void },
    body: IUpdateSchoolValidRequest,
  ) {
    try {
      context.commit(SET_LOADING, true);
      await SchoolUpdate(body.schoolId, body.data);
      context.commit(SET_LOADING, false);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
      router.push(body.redirectPath || {name: SCHOOL_ROUTER_NAMES.home});
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },

  async [SCHOOL_UPDATE_STUDENT_STATUSES](
    context: { commit: (arg0: string, arg1: any) => void },
    data: any
  ) {
    try {
      await SchoolUpdateStudentStatuses(data);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [SCHOOL_GET_STUDENT_DETAIL](
    context: { commit: (arg0: string, arg1: any) => void },
    studentId: number
  ) {
    try {
      const { data } = await SchoolGetStudentDetail(studentId);
      context.commit(SET_STUDENT_DETAIL, data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [SCHOOL_UPDATE_STUDENT](
    context: { commit: (arg0: string, arg1: any) => void },
    student: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await SchoolUpdateStudent(student.id, student.data);
      context.commit(SET_ERROR, null);
      context.commit(SET_LOADING, false);
      router.push(student.redirectPath || {name: SCHOOL_ROUTER_NAMES.studentAccounts})
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_GET_HISTORY_POINT_STUDENT](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await SchoolGetHistoryPointStudent(query);
      context.commit(SET_HISTORY_POINT_STUDENT, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_GET_HISTORY_POINT_USED](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await SchoolGetHistoryPointUsed(query);
      context.commit(SET_HISTORY_POINT_USED, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_GET_HISTORY_POINT_STATUSES](
    context: { commit: (arg0: string, arg1: any) => void },
  ) {
    try {
      const response = await SchoolGetHistoryPointStatuses();
      context.commit(SET_HISTORY_POINT_STATUSES, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_GET_DETAIL_HISTORY_POINT](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await SchoolGetDetailHistoryPoint(query);
      context.commit(SET_DETAIL_HISTORY_POINT, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_GET_LIST_AUTOMATIC_POINT](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await SchoolGetListAutomaticPoint(query);
      context.commit(SET_LIST_AUTOMATIC_POINT, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_CREATE_AUTOMATIC_POINT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await SchoolCreateAutomaticPoint(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
      router.push({name: SCHOOL_ROUTER_NAMES.automaticPointAwarded})
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_GET_DETAIL_AUTOMATIC_POINT](
    context: { commit: (arg0: string, arg1: any) => void },
    pointId: number
  ) {
    try {
      const { data } = await SchoolGetAutomaticPointDetail(pointId);
      context.commit(SET_DETAIL_AUTOMATIC_POINT, data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_UPDATE_AUTOMATIC_POINT](
    context: { commit: (arg0: string, arg1: any) => void },
    point: any
  ) {
    try {
      await SchoolUpdateAutomaticPoint(point.id, point.data);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
      router.push({name: SCHOOL_ROUTER_NAMES.automaticPointAwarded})
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_DELETE_AUTOMATIC_POINT](
    context: { commit: (arg0: string, arg1: any) => void },
    pointId: number,
  ) {
    try {
      await SchoolDeleteAutomaticPoint(pointId);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_IMPORT_STUDENTS](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await SchoolImportStudents(body);
      context.commit(SET_LOADING, false);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
      router.push({name: SCHOOL_ROUTER_NAMES.studentAccounts})
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_IMPORT_STUDENTS_VALIDATION](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      const response = await SchoolImportStudentsValidation(body);
      context.commit(SET_LOADING, false);
      context.commit(SET_LIST_VALIDATE_ERRORS, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [SCHOOL_DOWNLOAD_CSV](
    context: { commit: (arg0: string, arg1: any) => void },
  ) {
    try {
      const response = await SchoolDownloadCSV();
      context.commit(SET_ERROR, null);
      return response;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
};

const mutations = {
  [SET_ERROR](state: any, error: any) {
    state.errors = error;
  },
  [SET_LOADING](state: any, loading: boolean) {
    state.loading = loading;
  },
  [SET_LIST_FACULTIES](state: any, responseData: any) {
    state.faculties = responseData;
  },
  [SET_UPDATED_DATA](state: any, data: any) {
    state.updatedData = data;
  },
  [SET_SCHOOL_DETAIL](state: any, responseData: any) {
    state.school = responseData;
  },
  [SET_STUDENT_DETAIL](state: any, responseData: any) {
    state.student = responseData;
  },
  [SET_HISTORY_POINT_STUDENT](state: any, responseData: any) {
    state.historyPointStudent = responseData;
  },
  [SET_HISTORY_POINT_USED](state: any, responseData: any) {
    state.historyPointUsed = responseData;
  },
  [SET_HISTORY_POINT_STATUSES](state: any, responseData: any) {
    state.historyPointStatus = responseData;
  },
  [SET_DETAIL_HISTORY_POINT](state: any, responseData: any) {
    state.detailHistoryPoint = responseData;
  },
  [SET_LIST_STORE](state: any, responseData: any) {
    state.listStores = responseData;
  },
  [SET_LIST_AUTOMATIC_POINT](state: any, responseData: any) {
    state.automaticPoints = responseData;
  },
  [SET_DETAIL_AUTOMATIC_POINT](state: any, responseData: any) {
    state.automaticPoint = responseData;
  },
  [SET_LIST_VALIDATE_ERRORS](state: any, responseData: any) {
    state.listValidateErrors = responseData;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
